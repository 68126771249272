<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">NID Scraper</v-toolbar-title>
      <v-spacer />
      <v-btn outlined @click="() => this.fetchNidScraperRequests()">
        <v-icon class="mr-1">mdi-reload</v-icon>
        <div class="reload-text">Reload</div>
      </v-btn>
    </v-toolbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :search="filterText"
          :headers="nidScraperRequestHeaders"
          :items="nidScraperRequests"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :server-items-length="totalItems"
          disable-sort
          item-key="md5sum"
          class="elevation-2"
          id="match-table"
          :footer-props="footerProps"
          style="cursor: pointer"
          @click:row="(item) => $router.push(nidScraperSingle(item))"
        >
          <template v-slot:top>
            <div class="pa-2 lighten-2 d-flex align-center">
              <v-text-field
                :value="query"
                solo
                clearable
                @click:clear="searchPressed"
                hide-details
                placeholder="Search"
                prepend-inner-icon="mdi-magnify"
                @keypress.enter="searchPressed"
                @focusout="searchPressed"
              />
            </div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.requestUserId`]="{ item }">
            <v-btn
              v-if="item.requestUserId"
              outlined
              :to="{ name: 'user.view', params: { id: item.requestUserId } }"
            >
              {{ item.requestUserName || item.requestUserId }}
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small outlined :to="nidScraperSingle(item)"> More </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { NID_SCRAPER_REQUESTS } from "@/constants/api";

export default {
  name: "NidScrapperRequestIndex",
  metaInfo: { title: "NID Request List" },
  components: {},
  data() {
    return {
      loading: false,
      filterText: null,
      nidScraperRequestHeaders: [
        { text: "Requested At", value: "createdAt", sortable: true },
        { text: "Requested By", value: "requestUserId", sortable: true },
        { text: "NID", value: "nid", sortable: true },
        { text: "DOB", value: "dateOfBirth", sortable: true },
        // { text: "Name (English)", value: "nameEn", sortable: true },
        { text: "Name", value: "nameBn", sortable: true },
        { text: "", value: "actions", sortable: false },
      ],
      nidScraperRequests: [],
      roles: {},
      selectedOcrRequest: null,
      action: null,
      page: 1,
      query: null,
      itemsPerPage: 15,
      totalItems: null,
      optionsLength: 6,
      footerProps: { "items-per-page-options": [15, 30, 60, 100] },
    };
  },
  mounted() {
    this.query = this.$route.query.query || null;
    this.page = Number(this.$route.query.page) || 1;
    this.itemsPerPage = Number(this.$route.query.limit) || 15;
    this.fetchNidScraperRequests();
  },
  computed: {
    pagination() {
      return { page: this.page - 1, limit: this.itemsPerPage, query: this.query };
    },
  },
  watch: {
    pagination() {
      if (
        Number(this.$route.query.page) !== this.page ||
        Number(this.$route.query.limit) !== this.itemsPerPage
      ) {
        this.$router.push({
          query: { page: this.page, limit: this.itemsPerPage, query: this.query },
        });
      }
    },
    $route({ name }) {
      if (name === "nid-scraper.index") {
        this.query = this.$route.query.query || null;
        this.page = Number(this.$route.query.page) || 1;
        this.itemsPerPage = Number(this.$route.query.limit) || 15;
        this.fetchNidScraperRequests();
      }
    },
  },
  methods: {
    fetchNidScraperRequests() {
      this.loading = true;
      return this.$axios
        .get(NID_SCRAPER_REQUESTS, {
          params: this.pagination,
        })
        .then((response) => {
          if (response.data.code === 0) {
            this.totalItems = response.data.data.totalItems;
            this.nidScraperRequests = response.data.data.items;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    nidScraperSingle(nidScraper) {
      return {
        name: "nid-scraper.single",
        params: {
          requestId: nidScraper.requestId,
        },
      };
    },
    searchPressed(data) {
      if (data && data.target) {
        this.$router.push({
          query: { page: this.page, limit: this.itemsPerPage, query: data.target.value },
        });
      }
    },
  },
};
</script>
